body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.ant-btn.ant-btn-primary.ant-btn-sm {
  background-color: red;
}

@media (min-width:0px)  {
  #report-text-box{
    width: 100%;
  }
}

@media (min-width:320px)  { 
  #tile {
    width: 90%;
  }
  #image {
    background-position-x: -100px;
    align-items: flex-end;
  }
  #carousel-image {
    background-position-x: -180px;
    align-items: flex-end;
  }
  #carousel-report-text-box {
    width: 100%;
  }
}

@media (min-width:480px)  {
  #tile {
    width: 90%;
  }
  #image {
    background-position-x: -100px;
    align-items: flex-end;
  }
  #carousel-image {
    background-position-x: -120px;
    align-items: flex-end;
  }
  #carousel-report-text-box {
    width: 100%;
  }
}

@media (min-width:550px)  {
  #tile {
    width: 90%;
  }
  #carousel-image {
    background-position-x: 0px;
  }
  #image {
    background-position-x: 0px;
    align-items: flex-end;
  }
  #carousel-report-text-box {
    width: 100%;
    align-items: flex-end;
  }
}

@media (min-width:1100px) {
  #tile {
    width: 45%;
  }
  #carousel-image {
    background-position-x: 0px;
    align-items: flex-start;
  }
  #image {
    background-position-x: 0px;
    align-items: flex-end;
  }
  #carousel-report-text-box {
    width: 30%;
  }
}

@media (min-width:1250px) {
  #tile {
    width: 31%;
  }
  #carousel-tile {
    width: 31%;
    height: 80vh !important;
  }
  #carousel-image {
    background-position-x: 0px;
    align-items: flex-start;
  }
  #image {
    background-position-x: 0px;
    align-items: flex-end;
  }
  #carousel-report-text-box {
    width: 30%;
  }
}

@media (min-width:1300px) {
  #tile {
    width: 31%;
  }
  #carousel-tile {
    /* width: 31%; */
    height: 80vh !important;
  }
  #carousel-image {
    background-position-x: 0px;
    align-items: flex-start;
  }
  #image {
    background-position-x: 0px;
    align-items: flex-end;
  }
  #carousel-report-text-box {
    width: 30%;
  }
}

/* .ant-menu-item-selected, .ant-menu-item-active {
  color: #07D670 !important;
  border-bottom: 2px solid #07D670 !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 2px solid #07D670 !important;
}  */

.ant-menu-inline {
  border-right: 0px !important;
}